<template>
  <nav
    :class="{
      light: mode === 'light' || mode === 'plain-light',
      plain: mode === 'plain' || mode === 'plain-light',
      hidden: mode === 'hidden',
      shown: mobile && showToggle,
    }"
  >
    <div class="core-nav">
      <router-link to="/"><div class="logo"></div></router-link>
      <div class="right">
        <div
          v-if="mobile"
          :class="{ showToggle, light: mode === 'light' }"
          @click="showToggle = !showToggle"
        ></div>
        <template v-else>
          <router-link to="/about">About Us</router-link>
          <router-link to="/contact">Contact</router-link>
          <a href="https://preorder.gohercs.com/" class="btn">Pre-order</a>
        </template>
      </div>
    </div>
    <div :class="{ showToggle, dropDown: true }">
      <router-link to="/about" @click="showToggle = !showToggle"
        >About Us</router-link
      >
      <router-link to="/contact" @click="showToggle = !showToggle"
        >Contact</router-link
      >
      <a
        href="https://preorder.gohercs.com/"
        class="btn"
        @click="showToggle = !showToggle"
        >Pre-order</a
      >
    </div>
  </nav>
</template>

<script>
export default {
  name: "Nav",
  props: {
    mode: String,
  },
  data() {
    return {
      mobile: false,
      showToggle: false,
    };
  },
  mounted() {
    if (window.innerWidth < window.innerHeight) {
      this.mobile = true;
      window.addEventListener("scroll", () => (this.showToggle = false));
    }
  },
};
</script>

<style lang="scss" scoped>
nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(48, 49, 53, 0.8);
  backdrop-filter: blur(25px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #f9f9f8;
  transition: all 0.5s ease;
  z-index: 6;

  font-family: "Indivisible", Arial, sans-serif;

  &.hidden {
    opacity: 0;
    top: -80px;
  }

  &.light {
    background: rgba(249, 249, 251, 0.8);
    color: #303135;

    .logo {
      background: url(../assets/logo.png) no-repeat center center;
      background-size: contain;
    }
  }

  // @media (min-width: 100vh) {
  &.plain:not(.shown) {
    background: transparent;
    backdrop-filter: unset;
    color: #f9f9f8;

    .logo {
      background: url(../assets/logo_light.png) no-repeat center center;
      background-size: contain;
    }
  }
  // }

  .logo {
    width: 152.57px;
    height: 24px;
    background: url(../assets/logo_light.png) no-repeat center center;
    background-size: contain;

    @media (max-width: 100vh) {
      width: 101.71px;
      height: 16px;
    }
  }

  .core-nav {
    width: 100%;
    max-width: 1440px;
    padding: 24px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 100vh) {
      padding: 53px 20px 16px;
    }

    a {
      display: inline-block;
      min-width: 92px;
      text-align: center;
      color: inherit;
      text-decoration: none;
      font-size: 14px;
      line-height: 1.6;
      transition: all 0.3s ease;

      &:hover {
        color: #4f5af5;
      }

      &:active {
        color: #3f48c4;
      }
    }

    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;

      width: 104px;
      height: 32px;

      background: #4f5af5;
      border-radius: 25px;

      flex: none;
      order: 2;
      flex-grow: 0;
      transition: all 0.3s ease;
      font-weight: 600;

      font-size: 16px;
      line-height: 140%;
      /* or 22px */

      letter-spacing: 0.02em;
      color: #f9f9fb;

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
          ),
          #4f5af5;
        color: #f9f9fb;
      }

      &:active {
        background: #3f48c4;
        color: #f9f9fb;
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;

      > div {
        width: 18px;
        height: 14px;
        background: url(../assets/menu.png) no-repeat center center;
        background-size: contain;

        &.light {
          background: url(../assets/menu_dark.png) no-repeat center center;
          background-size: contain;
        }

        &.showToggle {
          background: url(../assets/close.png) no-repeat center center;
          background-size: contain;

          &.light {
            background: url(../assets/close_dark.png) no-repeat center center;
            background-size: contain;
          }
        }
      }
    }
  }

  .dropDown {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding-top: 0;
    padding-bottom: 0;

    box-sizing: border-box;
    height: 0;
    overflow: hidden;

    transition: 0.3s ease-in-out;

    &.showToggle {
      height: 204.8px;

      padding-top: 32px;
      padding-bottom: 32px;
    }

    a {
      display: inline-block;
      min-width: 100.57px;
      text-align: center;
      color: inherit;
      text-decoration: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.4;
      transition: all 0.3s ease;

      &:hover {
        color: #4f5af5;
      }

      &:active {
        color: #3f48c4;
      }
    }

    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;

      width: 104px;
      height: 32px;

      background: #4f5af5;
      border-radius: 25px;

      flex: none;
      order: 2;
      flex-grow: 0;
      transition: all 0.3s ease;
      font-weight: 600;

      font-size: 16px;
      line-height: 140%;
      /* or 22px */

      letter-spacing: 0.02em;
      color: #f9f9fb;

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
          ),
          #4f5af5;
        color: #f9f9fb;
      }

      &:active {
        background: #3f48c4;
        color: #f9f9fb;
      }
    }
  }
}
</style>
