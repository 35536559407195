<template>
  <div class="home" ref="home">
    <onboard @changeTheme="propogateTheme" />
    <landing @changeTheme="propogateTheme" />
  </div>
</template>

<script>
import Onboard from "../components/OnboardingScreen.vue";
import Landing from "../components/LandingPage.vue";

export default {
  name: "HomeView",
  data() {
    return {
      mobile: false,
      listener: null,
    };
  },
  components: {
    Onboard,
    Landing,
  },
  methods: {
    propogateTheme(theme) {
      this.$emit("changeTheme", theme);
    },
  },
  mounted() {
    this.$emit("changeTheme", "hidden");
    document.title = "HERCS";

    if (window.innerWidth < window.innerHeight) {
      this.mobile = true;
      this.listener = () => {
        if (window.scrollY > 0) {
          this.$emit("changeTheme", "dark");
        } else {
          this.$emit("changeTheme", "plain");
        }
      };
      window.addEventListener("scroll", this.listener);
    }
  },
  beforeUnmount() {
    if (this.mobile) {
      window.removeEventListener("scroll", this.listener);
    }
  },
};
</script>
