<template>
  <footer
    :class="{
      light: mode === 'light' || mode === 'plain-light',
      plain: mode === 'plain',
    }"
  >
    <div v-if="!mobile" class="core-footer">
      <div class="left">
        <p>Contact Us</p>
        <p style="width: 186px">Contact@gohercs.com</p>
      </div>
      <div>
        <router-link to="/privacy">Privacy Policy</router-link>
        <div class="divider"></div>
        <router-link to="/term">Terms of Use</router-link>
      </div>
      <div class="right">
        <p>Follow Us</p>
        <div class="icons">
          <a
            href="https://instagram.com/gohercs?igshid=OGQ5ZDc2ODk2ZA=="
            :style="{
              'mask-image': `url(${require('../assets/ins.png')})`,
            }"
          >
          </a>
          <a
            href="https://www.facebook.com/gohercs?mibextid=9R9pXO"
            :style="{
              'mask-image': `url(${require('../assets/facebook.png')})`,
            }"
          ></a>
          <a
            href="https://www.linkedin.com/company/gohercs/"
            :style="{
              'mask-image': `url(${require('../assets/linkin.png')})`,
            }"
          ></a>
        </div>
      </div>
    </div>
    <div v-else class="core-footer">
      <div>
        <router-link to="/privacy">Privacy Policy</router-link>
        <router-link to="/term">Terms of Use</router-link>
      </div>
      <div class="divider"></div>
      <div>
        <div>
          <p>Contact@gohercs.com</p>
        </div>
        <div>
          <div class="icons">
            <a
              href="https://instagram.com/gohercs?igshid=OGQ5ZDc2ODk2ZA=="
              :style="{
                'mask-image': `url(${require('../assets/ins.png')})`,
              }"
            >
            </a>
            <a
              href="https://www.facebook.com/gohercs?mibextid=9R9pXO"
              :style="{
                'mask-image': `url(${require('../assets/facebook.png')})`,
              }"
            ></a>
            <a
              href="https://www.linkedin.com/company/gohercs/"
              :style="{
                'mask-image': `url(${require('../assets/linkin.png')})`,
              }"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    mode: String,
  },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    if (window.innerWidth < window.innerHeight) {
      this.mobile = true;
    }
  },
};
</script>

<style lang="scss" scoped>
footer {
  margin: 0;
  width: 100%;
  background: rgba(48, 49, 53, 0.8);
  backdrop-filter: blur(25px);
  display: flex;
  justify-content: space-around;
  color: #f9f9f8;

  font-family: "Indivisible", Arial, sans-serif;

  &.light {
    background: rgba(249, 249, 251, 0.8);
    color: #303135;

    .divider {
      background: rgba(207, 207, 207, 1);
    }

    .core-footer .icons {
      gap: 2px;

      a {
        background: #303135;
      }
    }
  }

  &.plain {
    background: transparent;
    backdrop-filter: unset;
  }

  .core-footer {
    width: 100%;
    max-width: 1440px;
    padding: 24px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 100vh) {
      flex-direction: column;
      align-items: stretch;
      padding: 8px 0 24px;
      gap: 8px;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.6;
      text-align: center;
    }

    .divider {
      width: 1px;
      height: 22px;
      background: rgba(207, 207, 207, 0.2);

      @media (max-width: 100vh) {
        width: 100vw;
        height: 1px;
      }
    }

    a {
      display: inline-block;
      width: 93px;
      text-align: center;
      color: inherit;
      text-decoration: none;
      font-size: 14px;
      line-height: 1.6;
      transition: all 0.3s ease;

      &:hover {
        color: #4f5af5;
      }

      &:active {
        color: #3f48c4;
      }
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
    }

    .icons {
      gap: 2px;

      a {
        display: block;
        width: 32px;
        height: 32px;
        mask-mode: alpha;
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
        background: #fff;

        @media (max-width: 100vh) {
          width: 32px;
          height: 32px;
        }

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.1),
              rgba(255, 255, 255, 0.1)
            ),
            #4f5af5;
        }

        &:active {
          background: #3f48c4;
        }
      }
    }

    .right {
      margin-left: 96px;
    }
  }
}
</style>
