<template>
  <div class="landing">
    <div
      class="container"
      :style="{
        backgroundImage: `url(${require('../assets/equipment.png')})`,
        height: mobile ? '461px' : '594px',
      }"
    ></div>
    <div class="container sec1 watch">
      <presence>
        <motion
          class="stick"
          v-if="sec1Idx == 0"
          :initial="{ opacity: 0, top: '100px' }"
          :animate="{ opacity: 1, top: 0 }"
          :exit="{ opacity: 0 }"
          :transition="{ duration: 0.5 }"
          ><h2>
            eBELL<i class="trade">&trade;</i>:<br v-if="mobile" />
            AN AI-POWERED DEVICE
          </h2>
          <p>Enhances workout experiences by automatic exercise tracking.</p>
        </motion>
        <motion
          class="stick"
          v-else-if="sec1Idx == 1"
          :initial="{ opacity: 0, top: '100px' }"
          :animate="{ opacity: 1, top: 0 }"
          :exit="{ opacity: 0 }"
          :transition="{ duration: 0.5 }"
        >
          <h2>UNPARALLELED FITNESS INSIGHTS</h2>
          <p>Revolutionize the way workout evolves.</p>
        </motion>
      </presence>
    </div>
    <video-background
      class="sec2 watch"
      :src="sec2bg[sec2BgIdx]"
      :loop="false"
      @ended="sec2BgIdx = (sec2BgIdx + 1) % sec2bg.length"
      style="width: 100vw"
      :style="{
        height: mobile ? '460px' : '810px',
      }"
    >
      <div class="container">
        <motion
          v-if="sec2Show"
          :initial="{ opacity: 0, top: mobile ? '-196px' : '1078px' }"
          :animate="{ opacity: 1, top: mobile ? '-196px' : '277px' }"
          :transition="{
            duration: 0.3,
            easing: 'ease-in-out',
          }"
          :style="{
            position: 'absolute',
            left: mobile ? '0' : '80px',
            top: mobile ? '-196px' : '277px',
            right: '0',
            'max-width': mobile ? '100vw' : '686px',
            'margin-right': mobile ? '0' : '520px',
          }"
        >
          <h2><span>Real-time</span> Exercise Recognition &amp; Tracking</h2>
          <p>
            Seamlessly identify your chosen exercise, count performed reps, and
            detect selected weight for a fully automated workout experience.
          </p>
        </motion>
        <video-background
          class="iphone"
          :src="sec2Mobile[sec2BgIdx]"
          :loop="false"
          @ended="sec2BgIdx = (sec2BgIdx + 1) % sec2bg.length"
          :style="
            mobile
              ? `width: 143.28px; height: 310.33px`
              : `width: 275px; height: 594px`
          "
        >
          <div></div>
        </video-background>
      </div>
    </video-background>
    <div class="container sec3 watch">
      <motion
        v-if="sec3Show"
        class="desc"
        :initial="{ opacity: 0, top: '308px' }"
        :animate="{ opacity: 1, top: '60px' }"
        :transition="{
          duration: 1,
          easing: 'ease-in-out',
        }"
        ><h2>
          <span>HANDS-FREE</span><br v-if="mobile" />
          PERFORMANCE <br v-if="mobile" />
          Analyzing
        </h2>
        <p>
          Eliminate manual-logging interference by auto-tracking your workout
          records.
        </p>
      </motion>
      <motion
        v-if="sec3Show"
        class="phones"
        :initial="{ opacity: 0, top: mobile ? 0 : '712px' }"
        :animate="{ opacity: 1, top: 0 }"
        :transition="{
          duration: 1,
          easing: 'ease-in-out',
        }"
      >
        <video-background
          class="iphone"
          :src="require('../assets/video/phone/App_StatsFilter.mute.mp4')"
          :style="
            mobile
              ? `width: 145.3px; height: 314.79px`
              : `width: 251.62px; height: 544.04px`
          "
        >
          <div></div>
        </video-background>
      </motion>
      <motion
        v-if="sec3Show"
        class="phones"
        :initial="{ opacity: 0, top: mobile ? 0 : '1103px' }"
        :animate="{ opacity: 1, top: 0 }"
        :transition="{
          duration: 1,
          easing: 'ease-in-out',
        }"
      >
        <video-background
          class="iphone skew"
          :src="require('../assets/video/phone/Cuts_SkewFilter.mute.mp4')"
          :style="
            mobile
              ? `width: 130.78px; height: 316.23px`
              : `width: 225.7px; height: 546.53px`
          "
        >
          <div></div>
        </video-background>
      </motion>
    </div>
    <div style="background: #303135">
      <div class="container sec4 watch">
        <presence>
          <motion
            class="stick desc"
            v-if="sec4Idx == 0"
            :initial="{ opacity: 0, top: '100px' }"
            :animate="{ opacity: 1, top: 0 }"
            :exit="{ opacity: 0 }"
            :transition="{ duration: 0.5 }"
            ><h2>
              in-depth<br />
              training reports
            </h2>
            <p>Analyze every aspect of your workout dynamics.</p>
          </motion>
          <motion
            class="stick desc"
            v-else-if="sec4Idx == 1"
            :initial="{ opacity: 0, top: '100px' }"
            :animate="{ opacity: 1, top: 0 }"
            :exit="{ opacity: 0 }"
            :transition="{ duration: 0.5 }"
          >
            <h2>ALL EXERCISE, WEIGHTS, SETS, &amp; REPS</h2>
            <p>Monitor your fitness progress without delays.</p>
          </motion>
        </presence>
        <div class="noti-box">
          <template v-for="i in 4" :key="i">
            <presence>
              <motion
                class="noti"
                v-if="sec4NotiIdx >= i"
                :initial="{ opacity: 0, height: 0, top: '202px' }"
                :animate="{ opacity: 1, height: '65px', top: 0 }"
                :exit="{
                  opacity: 0,
                  top: `-${67 * (i - 1)}px`,
                  transform: `scale(${1 - 0.05 * i})`,
                }"
                :transition="{ delay: 0, duration: 0.1 }"
                :style="{
                  backgroundImage: `url(${require(`../assets/noti${i}.png`)})`,
                }"
              ></motion>
            </presence>
          </template>
        </div>
        <div
          class="chart tchart"
          :style="{
            backgroundImage: `url(${require(`../assets/tchart-1.png`)})`,
          }"
        ></div>
        <presence>
          <template v-for="i in 5" :key="i">
            <motion
              class="chart tchart"
              v-if="sec4NotiIdx == i - 1 && i > 1"
              :initial="{ opacity: 0 }"
              :animate="{ opacity: 1, transition: { easing: 'linear' } }"
              :exit="{ opacity: 0, transition: { easing: 'linear' } }"
              :transition="{ duration: 0.3, easing: 'linear' }"
              :style="{
                backgroundImage: `url(${require(`../assets/tchart${
                  i - 2
                }.png`)})`,
              }"
            ></motion>
          </template>
        </presence>
        <div
          class="chart bchart"
          :style="{
            backgroundImage: `url(${require(`../assets/bchart-1.png`)})`,
          }"
        ></div>
        <presence>
          <template v-for="i in 5" :key="i">
            <motion
              class="chart bchart"
              v-if="sec4NotiIdx == i - 1 && i > 1"
              :initial="{ opacity: 0 }"
              :animate="{ opacity: 1, transition: { easing: 'linear' } }"
              :exit="{ opacity: 0, transition: { easing: 'linear' } }"
              :transition="{ duration: 0.3, easing: 'linear' }"
              :style="{
                backgroundImage: `url(${require(`../assets/bchart${
                  i - 2
                }.png`)})`,
              }"
            ></motion>
          </template>
        </presence>
      </div>
    </div>
    <div class="sec5 watch">
      <presence>
        <template v-for="i in 8" :key="i">
          <motion
            class="product"
            v-if="sec5Idx == i - 1"
            :initial="{ opacity: 0 }"
            :animate="{ opacity: 1 }"
            :exit="{
              opacity: 0,
            }"
            :transition="{ duration: 0.25 }"
            :style="{
              backgroundImage: `url(${require(`../assets/product${sec5Imgs[sec5Idx]}${resolutionExt}.png`)})`,
            }"
          ></motion>
        </template>
      </presence>
      <div class="container" v-if="sec5Show">
        <div class="desc">
          <motion
            v-for="i in 4"
            :key="i"
            :initial="{ opacity: 0 }"
            :animate="{ opacity: 1 }"
            :transition="{ duration: 0.3, delay: 0.3 * (i - 1) }"
            :style="{
              backgroundImage: `url(${require(`../assets/sec5${i}.png`)})`,
            }"
          ></motion>
        </div>
      </div>
    </div>
    <div class="container sec6 watch">
      <motion
        tag="h2"
        v-if="sec6Show"
        :initial="{ opacity: 0, paddingTop: '100px' }"
        :animate="{ opacity: 1, paddingTop: 0 }"
        :transition="{ duration: 0.5 }"
        >Your <span>Seamless</span> and <span>Intuitively</span> Simple
        Solutions</motion
      >
    </div>
    <video-background
      class="easy-video"
      v-if="sec6Show"
      :src="easyVideobg[easyVideoBgIdx].video"
      :loop="false"
      @ended="easyVideoBgIdx = (easyVideoBgIdx + 1) % easyVideobg.length"
      :style="
        mobile ? `width: 100vw; height: 460px` : `width: 100vw; height: 810px`
      "
    >
      <div class="container">
        <div
          :style="{
            position: 'absolute',
            left: mobile ? '20px' : '80px',
            top: mobile ? '183px' : '349px',
            right: '0',
            'max-width': mobile ? '89.333vw' : '686px',
            'margin-right': mobile ? '0' : '520px',
          }"
        >
          <h2>
            <span>{{ easyVideobg[easyVideoBgIdx].title }}</span>
          </h2>
          <p>{{ easyVideobg[easyVideoBgIdx].desc }}</p>
        </div>
      </div>
    </video-background>
    <div class="sec7 watch">
      <motion
        v-if="sec7Show"
        :initial="{ opacity: 0, top: '100px' }"
        :animate="{ opacity: 1, top: 0 }"
        :transition="{
          duration: 0.5,
          easing: 'ease-in-out',
        }"
        style="position: absolute; left: 0; width: 100%"
      >
        <div v-if="sec7Step == 0" class="container">
          <presence>
            <template v-for="i in 3" :key="i">
              <motion
                class="gallery"
                v-if="sec7Gallery == i - 1"
                :initial="{ opacity: 0, top: '900px' }"
                :animate="{ opacity: 1, top: '72px' }"
                :exit="{ opacity: 0, top: '-700px' }"
                :transition="{ duration: 0.5 }"
                :style="{
                  backgroundImage: `url(${require(`../assets/sec7${i}${resolutionExt}.png`)})`,
                }"
              >
              </motion>
            </template>
          </presence>
          <div
            :style="
              mobile
                ? `position: absolute; left: 20px; top: 32px; width: 89.333vw`
                : `position: absolute; left: 80px; top: 362px; width: 524px`
            "
          >
            <h2>
              <span>Wristband Accessory</span>
            </h2>
            <p>Support body weights and cardio.</p>
          </div>
        </div>
        <video-background
          v-else-if="sec7Step == 1"
          :src="require(`../assets/video/background/Sec3_Cardio${resolutionExt}.mute.mp4`)"
          :loop="false"
          @ended="sec7start"
          style="width: 100vw; height: 910px"
        >
          <div class="container">
            <div
              :style="{
                position: 'absolute',
                left: mobile ? '20px' : '80px',
                top: mobile ? '32px' : '362px',
                right: '0',
                'max-width': mobile ? '89.333vw' : '597px',
                'margin-right': mobile ? '0' : '755px',
              }"
            >
              <h2>
                <span>BODY WEIGHTS &amp; MORE</span>
              </h2>
              <p>
                Boost strength training like push-ups and crunches, as well as
                cardio.
              </p>
            </div>
          </div>
        </video-background>
      </motion>
    </div>
    <div
      class="sec8 container"
      style="height: 280px"
      :style="mobile ? `height: 171.53px` : `height: 280px`"
    >
      <a href="https://preorder.gohercs.com/" class="btn">eBell<i class="trade">&trade;</i> Pre-order</a>
      <p
        :style="
          mobile
            ? `font-weight: 400; position:relative; top: 111.53px`
            : `font-weight: 400; position:relative; top: 206px; text-align: center; font-size: 14px`
        "
      >
      &copy; 2023 HERCS Technology Inc. All Rights Reserved. Pat. Pend.
      </p>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import { Loader } from "resource-loader";

export default {
  data() {
    return {
      sec1Idx: -1,
      sec1Loop: null,
      sec2BgIdx: 0,
      sec2MobileIdx: 0,
      sec2Mobile: [
        // require("../assets/video/phone/App_1_Connect.mute.mp4"),
        require("../assets/video/phone/App_2_DumbbellCurl.mute.mp4"),
        require("../assets/video/phone/App_3_InclineBench.mute.mp4"),
        require("../assets/video/phone/App_4_KettkeSwing.mute.mp4"),
        require("../assets/video/phone/App_5_Pushup.mute.mp4"),
        // require("../assets/video/phone/App_6_CurltoIncline.mute.mp4"),
      ],
      sec2Show: false,
      sec3Show: false,
      sec4Show: false,
      sec4Idx: -1,
      sec4NotiIdx: 0,
      sec4NotiIdxLazy: 1,
      sec4Loop: null,
      sec4NotiLoop: null,
      sec5Idx: -1,
      sec5Imgs: [],
      sec5Loop: null,
      sec5Show: false,
      sec6Show: false,
      easyVideoBgIdx: 0,
      sec7Show: false,
      sec7Step: 0,
      sec7Gallery: 0,
      mobile: false,
      resolutionExt: "",
    };
  },
  watch: {
    sec2BgIdx() {
      const loader = new Loader();
      loader.add(this.sec2bg[(this.sec2BgIdx + 1) % this.sec2bg.length]); // Pre-load the next video
      loader.load();
    },
    sec2MobileIdx() {
      const loader = new Loader();
      loader.add(
        this.sec2Mobile[(this.sec2MobileIdx + 1) % this.sec2Mobile.length]
      ); // Pre-load the next video
      loader.load();
    },
    easyVideobgIdx() {
      const loader = new Loader();
      loader.add(
        this.easyVideobg[(this.easyVideoBgIdx + 1) % this.easyVideobg.length]
          .video
      ); // Pre-load the next video
      loader.load();
    },
  },
  computed: {
    scrollWatcher() {
      return () => {
        document.querySelectorAll(".landing .watch").forEach((el) => {
          if (
            el.getBoundingClientRect().top < (window.innerHeight / 3) * 2 ||
            this.mobile
          ) {
            if (!el.classList.contains("invoked")) {
              el.classList.add("invoked");
              const sec = [...el.classList].find((c) => c.startsWith("sec"));
              this[`${sec}start`]();
            }
          }
        });
      };
    },
    sec2bg() {
      return [
        require(`../assets/video/background/Sec1_1_bumbbell curl${this.resolutionExt}.mute.mp4`),
        require(`../assets/video/background/Sec1_2_InclineBench${this.resolutionExt}.mute.mp4`),
        require(`../assets/video/background/Sec1_3_kettlebell swing${this.resolutionExt}.mute.mp4`),
        require(`../assets/video/background/Sec1_4_Pushup${this.resolutionExt}.mute.mp4`),
      ];
    },
    easyVideobg() {
      return [
        {
          video: require(`../assets/video/background/Sec2_1_NoPreset${this.resolutionExt}.mute.mp4`),
          title: "No Prep",
          desc: "Start your workout as immediately as you want.",
        },
        {
          video: require(`../assets/video/background/Sec2_2_Switch Weight${this.resolutionExt}.mute.mp4`),
          title: "Switch weight",
          desc: "Exercise yourself as much as you would like.",
        },
        {
          video: require(`../assets/video/background/Sec2_3_BandtoDumbbell${this.resolutionExt}.mute.mp4`),
          title: "Anywhere",
          desc: "On gears or wristbands.",
        },
        {
          video: require(`../assets/video/background/Sec2_4_Freeweight+Bodyweight${this.resolutionExt}.mute.mp4`),
          title: "Anything",
          desc: "For free weights or body weights.",
        },
        {
          video: require(`../assets/video/background/Sec2_5_Review${this.resolutionExt}.mute.mp4`),
          title: "anytime",
          desc: "Freely check out your workout progress.",
        },
      ];
    },
  },
  methods: {
    sec1start() {
      this.sec1Idx = 0;
      this.sec1Loop = setInterval(() => {
        const next = (this.sec1Idx + 1) % 2;
        this.sec1Idx = -1;
        setTimeout(() => {
          this.sec1Idx = next;
        }, 500);
      }, 5000);
    },
    sec2start() {
      this.sec2Show = true;
    },
    sec3start() {
      this.sec3Show = true;
    },
    sec4start() {
      this.sec4Show = true;
      this.sec4Idx = 0;
      this.sec4Loop = setInterval(() => {
        const next = (this.sec4Idx + 1) % 2;
        this.sec4Idx = -1;
        setTimeout(() => {
          this.sec4Idx = next;
        }, 500);
      }, 3000);
      this.sec4NotiLoop = setInterval(() => {
        this.sec4NotiIdx = (this.sec4NotiIdx + 1) % 5;
        if (this.sec4NotiIdx > 0 && this.sec4NotiIdxLazy == 0)
          this.sec4NotiIdxLazy = 1;
        else if (this.sec4NotiIdx == 0) this.sec4NotiIdxLazy = 0;
      }, 800);
    },
    sec5start() {
      this.sec5Show = true;
      setTimeout(() => {
        this.sec5Loop = setInterval(() => {
          if (!this.sec5Imgs.length) return;
          this.sec5Idx = (this.sec5Idx + 1) % this.sec5Imgs.length;
        }, 300);
      }, 600);
    },
    sec6start() {
      this.sec6Show = true;
    },
    sec7start() {
      this.sec7Show = true;
      this.sec7Step = 0;
      this.sec7Gallery = 0;
      setTimeout(() => {
        this.sec7Gallery = 1;
      }, 1000);
      setTimeout(() => {
        this.sec7Gallery = 2;
      }, 2500);
      setTimeout(() => {
        this.sec7Step = 1;
      }, 4000);
    },
  },
  mounted() {
    if (window.innerWidth < 1920) {
      this.resolutionExt = ".1920";
    }
    if (window.innerWidth < 1280) {
      this.resolutionExt = ".1280";
    }

    AOS.init();
    window.addEventListener("scroll", this.scrollWatcher);
    for (let i = 1; i <= 8; i++) {
      const img = new Image();
      const _i = i;
      img.src = require(`../assets/product${i}${this.resolutionExt}.png`);
      img.onload = () => {
        this.sec5Imgs.push(_i);
      };
    }
    for (let i = 1; i <= 3; i++) {
      const img = new Image();
      img.src = require(`../assets/sec7${i}${this.resolutionExt}.png`);
    }

    if (window.innerWidth < window.innerHeight) {
      this.mobile = true;
    }
    this.scrollWatcher();
  },
  beforeUnmount() {
    clearInterval(this.sec1Loop);
    window.removeEventListener("scroll", this.scrollWatcher);
  },
};
</script>

<style lang="scss" scoped>
.landing {
  @media (max-width: 100vh) {
    margin-top: 85px;
  }

  .container {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: 100vh) {
      background-size: cover;
    }
  }

  .sec1 {
    height: 470px;
    position: relative;

    h2 {
      background: linear-gradient(65.18deg, #ed3268 18.06%, #4f5af5 87.61%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    p {
      color: white;
      text-transform: uppercase;
    }

    h2,
    p {
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;
      text-align: center;
      margin: 0 auto;
      width: 848px;
      position: relative;

      @media (max-width: 100vh) {
        font-size: 24px;
        width: 89.3vw;
      }
    }
  }

  .stick {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .iphone {
    // background-color: #f2f2f2;
    background-color: #000;

    div {
      position: relative;
      left: -17px;
      top: -15px;
      width: 309px;
      height: 624px;
      background-image: url(../assets/iphone.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      mask-image: url(../assets/iphone_mask.png);
      mask-position: center;
      mask-size: contain;
      mask-repeat: no-repeat;

      @media (max-width: 100vh) {
        left: -8.86px;
        top: -7.84px;
        width: 161px;
        height: 326px;
      }
    }

    &.skew div {
      background-image: url(../assets/iphone_skew.png);
      mask-image: url(../assets/iphone_mask_skew.png);
    }
  }

  .sec2,
  .sec3,
  .sec4,
  .sec6,
  .easy-video,
  .sec7,
  .sec8 {
    overflow: hidden;

    @media (max-width: 100vh) {
      overflow: visible;
    }

    .iphone {
      position: absolute;
      top: 93px;
      right: 187px;
      overflow: visible;

      @media (max-width: 100vh) {
        top: 74.84px;
        right: 28.86px;
      }
    }

    h2 {
      margin: 0;
      font-weight: 700;
      font-size: 48px;
      line-height: 140%;
      text-transform: uppercase;
      color: #fff;

      span {
        color: #4f5af5;
      }

      @media (max-width: 100vh) {
        font-size: 24px;
        text-align: center;
      }
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 160%;
      color: #cfcfcf;

      @media (max-width: 100vh) {
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.02em;
        text-align: center;
        padding: 0 30px;
      }
    }
  }

  .sec3 {
    height: 900px;

    @media (max-width: 100vh) {
      height: 646.47px;
    }

    .desc {
      position: absolute;
      left: 80px;
      right: 0;
      max-width: 468px;
      margin-right: 900px;

      @media (max-width: 100vh) {
        left: 0;
        right: 0;
        max-width: 100vw;
        margin-right: 0;
      }
    }

    .iphone {
      top: 188px;
      right: 598.23px;

      @media (max-width: 100vh) {
        top: 231.31px;
        left: 27.28px;
      }

      div {
        left: -12.6px;
        top: -9.17px;
        width: 277.77px;
        height: 562px;

        @media (max-width: 100vh) {
          left: -7.28px;
          top: -5.31px;
          width: 160.41px;
          height: 325.18px;
        }
      }

      &.skew {
        top: 298px;
        right: 296px;

        @media (max-width: 100vh) {
          top: 293.52px;
          left: auto;
          right: 33.73px;
        }

        div {
          left: -8.61px;
          top: -7.3px;
          width: 258px;
          height: 562px;

          @media (max-width: 100vh) {
            left: -4.99px;
            top: -4.32px;
            width: 149.5px;
            height: 325.18px;
          }
        }
      }
    }

    .phones {
      position: relative;
    }
  }

  .sec4 {
    height: 900px;

    @media (max-width: 100vh) {
      height: 741px;
    }

    .desc {
      position: absolute;
      left: 80px;
      right: 0;
      max-width: 615px;
      margin-right: 595px;
      transform: translateY(149px);

      @media (max-width: 100vh) {
        left: 0;
        right: 0;
        max-width: 100vw;
        margin-right: 0;
        transform: translateY(24px);

        h2 {
          margin: 0 20px;
        }

        p {
          width: 80.2vw;
          margin: 0 auto;
        }
      }
    }

    h2 {
      text-transform: uppercase;
    }

    .noti-box {
      position: absolute;
      left: 80px;
      bottom: 59px;
      width: 387px;
      height: 665px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;

      @media (max-width: 100vh) {
        display: none;
      }

      .noti {
        position: relative;
        width: 320px;
        height: 65px;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        transition: 0.1s ease-in-out;
      }
    }

    .chart {
      position: absolute;
      right: 188px;
      width: 383px;
      height: 260.65px;

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: 100vh) {
        left: 50%;
        transform: translateX(-50%);
        width: 367px;
        height: 260.65px;
      }

      &.tchart {
        top: 149px;

        @media (max-width: 100vh) {
          top: 176px;
        }
      }

      &.bchart {
        bottom: 149px;

        @media (max-width: 100vh) {
          bottom: 24.35px;
        }
      }
    }
  }

  .product {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .sec5 {
    height: 810px;
    position: relative;

    @media (max-width: 100vh) {
      height: 440px;
    }

    .desc {
      position: absolute;
      left: 80px;
      top: 251px;

      @media (max-width: 100vh) {
        left: 10px;
        top: 32.53px;
      }

      > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 714px;
        height: 308px;
        background-position: top left;
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 100vh) {
          width: 279px;
          height: 308px;
        }
      }
    }
  }

  .sec6 {
    height: 540px;

    @media (max-width: 100vh) {
      height: 166px;
    }

    h2 {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 848px;

      @media (max-width: 100vh) {
        width: 335px;
      }
    }
  }

  @media (max-width: 100vh) {
    .easy-video {
      h2,
      p {
        text-align: left;
        padding: 0;
      }

      p {
        width: 74.13vw;
      }
    }
  }

  .sec7 {
    height: 900px;
    overflow: hidden;
    position: relative;

    @media (max-width: 100vh) {
      height: 460px;
    }

    .gallery {
      position: absolute;
      right: 0;
      width: 1099px;
      height: 700px;

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: 100vh) {
        width: 100vw;
        height: 400px;
      }
    }
  }

  .btn {
    text-align: center;
    text-decoration: none;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 416px;
    height: 40px;

    background: #4f5af5;
    border-radius: 8px;

    flex: none;
    order: 2;
    flex-grow: 0;
    transition: all 0.3s ease;
    font-weight: 600;

    font-size: 16px;
    line-height: 40px;
    /* or 22px */

    letter-spacing: 0.02em;
    color: #f9f9fb;

    @media (max-width: 100vh) {
      width: 89.3333vw;
      height: 40px;
      top: 59.53px;
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.1)
        ),
        #4f5af5;
      color: #f9f9fb;
    }

    &:active {
      background: #3f48c4;
      color: #f9f9fb;
    }
  }

  .trade {
    color: inherit;
    text-decoration: none;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    padding-left: 0.1em;
  }
}
</style>

<style lang="scss">
.landing {
  .video-wrapper {
    display: flex !important;
  }

  .sec3 .iphone.skew video {
    transform: translate(-50%, -50%) scale(1.06);
    transform-origin: center;
  }
}
</style>
