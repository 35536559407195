<template>
  <div
    class="core-container"
    :class="{
      light: mode === 'light' || mode === 'plain-light',
      hidden: mode === 'hidden',
    }"
  >
    <nav-bar :mode="mode" />
    <router-view @changeTheme="changeTheme" style="flex-grow: 1" />
    <footer-bar :mode="mode" />
  </div>
</template>

<script>
import NavBar from "./components/Nav.vue";
import FooterBar from "./components/Footer.vue";

export default {
  name: "App",
  data() {
    return {
      mode: "dark",
      listener: null,
    };
  },
  methods: {
    changeTheme(theme) {
      this.mode = theme;
      if (theme === "hidden") {
        this.listener = (e) => {
          e.preventDefault();
          e.stopPropagation();
        };
        window.addEventListener("touchmove", this.listener, { passive: false });
      } else {
        window.removeEventListener("touchmove", this.listener);
      }
    },
  },
  components: {
    NavBar,
    FooterBar,
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  font-family: "Indivisible", Arial, sans-serif;
}

#app {
  margin: 0;
  padding: 0;
}

.core-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #000;
  overflow-x: hidden;

  &.light {
    background: #f4f4f4;
  }

  &.hidden {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: touch;
  }
}

* {
  box-sizing: border-box;
}
</style>
