import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VideoBackground from "vue-responsive-video-background-player";
import { Motion, Presence } from "motion/vue";
import VueVideoPlayer from "@videojs-player/vue";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import "video.js/dist/video-js.css";
import 'aos/dist/aos.css';
import 'vue3-carousel/dist/carousel.css'
import "./assets/font/indivisible.css";

createApp(App)
  .use(router)
  .use(VueVideoPlayer)
  .component("video-background", VideoBackground)
  .component("Presence", Presence)
  .component("Motion", Motion)
  .component('Carousel', Carousel)
  .component('Slide', Slide)
  .component('Pagination', Pagination)
  .component('Navigation', Navigation)
  .mount("#app");
