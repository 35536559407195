<template>
  <div class="onboard">
    <motion
      ref="keyframe"
      class="logo"
      v-if="animateStep === 0"
      :initial="{ opacity: 0 }"
      :animate="{ opacity: 1 }"
      :transition="{
        delay: 0.8,
        duration: 1,
        easing: 'ease-in',
      }"
      @motioncomplete="nextStep"
    ></motion>
    <motion
      ref="keyframe"
      class="logo"
      v-else-if="animateStep === 1"
      :initial="{ width: mobile ? '260px' : '470.43px' }"
      :animate="{ width: mobile ? '263px' : '532.43px' }"
      :transition="{
        delay: 1,
        duration: 1.6,
        easing: 'ease-in-out',
      }"
      @motioncomplete="nextStep"
    >
      <div class="mask"></div>
      <p class="slogan">Make Your Gym Smart</p>
    </motion>
    <motion
      ref="keyframe"
      class="logo"
      v-else-if="animateStep === 2"
      :initial="{ opacity: 1, width: mobile ? '263px' : '532.43px' }"
      :animate="{ opacity: 0 }"
      :transition="{
        delay: 1,
        duration: 0.3,
        easing: 'ease-in-out',
      }"
      @motioncomplete="nextStep"
    >
      <div class="mask stick"></div>
      <p class="slogan stick">Make Your Gym Smart</p>
    </motion>
    <motion
      ref="keyframe"
      v-else-if="animateStep === 3"
      :initial="{ opacity: 0 }"
      :animate="{ opacity: 1 }"
      :transition="{
        delay: 1,
        duration: 0.3,
        easing: 'ease-in-out',
      }"
      @motioncomplete="nextStep"
    >
      <video-background
        :src="require(`../assets/video/background/Trailer(LandingPage)${resolutionExt}.mute.mp4`)"
        style="width: 100vw; height: 100vh"
      >
        <motion
          class="middle-hint"
          :initial="{ opacity: 0 }"
          :animate="{ opacity: 1 }"
          :transition="{
            delay: 2.1,
            duration: 0.5,
            easing: 'ease-in-out',
          }"
        >
          <a
            href="javascript:void(0)"
            :style="{
              'mask-image': `url(${require('../assets/watch.png')})`,
            }"
            @click="play"
          >
          </a
          >
          <!-- <a
            href="javascript:void(0)"
            :style="{
              'mask-image': `url(${require('../assets/detail.png')})`,
            }"
          >
          </a> -->
        </motion>
        <motion
          class="bottom-hint"
          :initial="{ opacity: 0, bottom: '-66px' }"
          :animate="{ opacity: 1, bottom: '61px' }"
          :transition="{
            delay: 2.1,
            duration: 0.5,
            easing: 'ease-in-out',
          }"
        >
          <div
            class="mouse-outline"
            :style="{
              'background-image': `url(${require('../assets/mouse-outline.png')})`,
            }"
          >
            <div
              class="mouse-inner"
              :style="{
                'background-image': `url(${require('../assets/mouse-inner.png')})`,
              }"
            ></div>
          </div>
          <div>scroll</div>
        </motion>
      </video-background>
    </motion>
    <div v-if="showVideo" class="video-player">
      <a href="javascript:void(0)" class="close-btn" @click="showVideo = false"
        >Close</a
      >
      <video-player
        :src="require('../assets/video/Trailer.mp4')"
        controls
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animateStep: 0,
      showVideo: false,
      lock: false,
      mobile: false,
      resolutionExt: "",
    };
  },
  props: {
    restartFlag: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    scrollCbk() {
      return (e) => {
        setTimeout(() => {
          if (!window.scrollY) this.$emit("changeTheme", "plain");
        }, 100);
        if (e.deltaY > 0 && !this.lock) {
          e.preventDefault();
          e.stopPropagation();
          this.lock = true;
          window.scroll({
            left: 0,
            top: window.innerHeight,
            behavior: "smooth",
          });
          setTimeout(() => {
            this.lock = false;
          }, 600);
          this.$emit("changeTheme", "dark");
        } else if (this.lock) {
          e.preventDefault();
          e.stopPropagation();
        }
      };
    },
  },
  watch: {
    animateStep() {
      this.$nextTick().then(() => {
        if (!this.$refs.keyframe) {
          this.animateStep--;
        }
      });
    },
    restartFlag() {
      this.animateStep = 0;
      this.$emit("changeTheme", "hidden");
    },
  },
  methods: {
    nextStep() {
      const totalSteps = 4;
      if (this.animateStep < totalSteps - 1) this.animateStep++;
      else
        setTimeout(() => {
          this.$emit("changeTheme", "plain");
          document
            .querySelector(".onboard")
            .addEventListener("mousewheel", this.scrollCbk, {
              passive: false,
            });
        }, 800);
    },
    play() {
      this.showVideo = true;
    },
  },
  mounted() {
    if (window.innerWidth < 1920) {
      this.resolutionExt = ".1920";
    }
    if (window.innerWidth < 1280) {
      this.resolutionExt = ".1280";
    }
    if (window.innerWidth < window.innerHeight) {
      this.mobile = true;
    }
  },
  beforeUnmount() {
    document
      .querySelector(".onboard")
      .removeEventListener("mousewheel", this.scrollCbk);
  },
};
</script>

<style lang="scss" scoped>
@property --mask-width-percent {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: false;
}

@property --mask-width {
  syntax: "<length>";
  initial-value: 0;
  inherits: false;
}

@property --mask-width-mobile {
  syntax: "<length>";
  initial-value: 0;
  inherits: false;
}

@keyframes mask-grow {
  0% {
    --mask-width: 0;
    --mask-width-mobile: 0;
    --mask-width-percent: 0%;
  }

  100% {
    --mask-width: 438px;
    --mask-width-mobile: 211px;
    --mask-width-percent: 100%;
  }
}

@keyframes text-grow {
  0% {
    left: 64px;
    opacity: 0;
    --mask-width-percent: 0%;
  }

  100% {
    left: 95px;
    opacity: 1;
    --mask-width-percent: 100%;
  }
}

@keyframes text-grow-mobile {
  0% {
    left: 0;
    opacity: 0;
    --mask-width-percent: 0%;
  }

  100% {
    left: 46px;
    opacity: 1;
    --mask-width-percent: 100%;
  }
}

@keyframes mouse-outline-hint {
  0% {
    opacity: 0.66;
  }

  31.37% {
    opacity: 0.66;
  }

  37.25% {
    opacity: 1;
  }

  88.24% {
    opacity: 1;
  }

  100% {
    opacity: 0.66;
  }
}

@keyframes mouse-hint {
  0% {
    top: 36px;
    opacity: 0;
  }

  31.37% {
    top: 36px;
    opacity: 0;
  }

  37.25% {
    top: 21.6px;
    opacity: 1;
  }

  43.14% {
    top: 3.6px;
  }

  49.02% {
    top: 6px;
  }

  88.24% {
    top: 6px;
    opacity: 1;
  }

  100% {
    top: 36px;
    opacity: 0;
  }
}

.onboard {
  width: 100vw;
  height: 100vh;

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 470.43px;
    height: 74px;
    background: url(../assets/logo.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;

    @media (max-width: 100vh) {
      width: 260px;
      height: 40px;
    }

    .stick {
      animation-delay: -999s !important;
    }

    .mask {
      position: absolute;
      top: 0;
      right: 0;
      width: var(--mask-width);
      height: 74px;
      background: linear-gradient(
        to left,
        #000 0%,
        #000 var(--mask-width-percent),
        transparent 100%
      );
      animation-name: mask-grow;
      animation-delay: 1s;
      animation-duration: 800ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      @media (max-width: 100vh) {
        width: var(--mask-width-mobile);
      }
    }

    .slogan {
      position: absolute;
      top: 18px;
      left: 95px;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      word-break: keep-all;
      white-space: nowrap;
      margin: 0;
      width: 435px;

      background: linear-gradient(
        to right,
        #ffffff 0%,
        #ffffff calc(var(--mask-width-percent) - 0.1%),
        #ffffff00 var(--mask-width-percent),
        #ffffff00 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

      animation-name: text-grow;
      animation-delay: 1.5s;
      animation-duration: 300ms;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      @media (max-width: 100vh) {
        font-size: 16px;
        top: 15px;
        left: 46px;
        width: 217px;
        animation-name: text-grow-mobile;
      }
    }
  }

  .bottom-hint {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 66px;
    bottom: 61px;

    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #f9f9fb;

    div {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .mouse-outline {
      position: relative;
      width: 24px;
      height: 36px;
      margin: 0 auto 8px;
      overflow: hidden;

      animation-name: mouse-outline-hint;
      animation-iteration-count: infinite;
      animation-duration: 2550ms;
      animation-timing-function: ease-in;
    }

    .mouse-inner {
      position: absolute;
      width: 4.8px;
      height: 16.8px;
      left: 9.6px;

      animation-name: mouse-hint;
      animation-iteration-count: infinite;
      animation-duration: 2550ms;
      animation-timing-function: ease-in;
    }
  }

  .middle-hint {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 270px;
    height: 22px;
    bottom: 247px;
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 100vh) {
      flex-direction: column;
      width: 115px;
      height: 84px;
    }

    a {
      display: block;
      width: 115px;
      height: 22px;
      mask-mode: alpha;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center center;
      background: #fff;
      transition: all 0.3s ease;

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
          ),
          #4f5af5;
      }

      &:active {
        background: #3f48c4;
      }
    }
  }

  .video-player {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 80vh;
    text-align: right;
    z-index: 999;

    > div {
      width: 100%;
      height: 100%;
    }

    .close-btn {
      display: inline-block;
      color: white;
      text-decoration: none;
      margin-left: auto;
      transition: all 0.3s ease;
      padding: 8px 16px;
      background: rgba(48, 49, 53, 0.8);

      &::before {
        display: inline-block;
        content: "\00d7"; /* This will render the 'X' */
        margin-right: 8px;
      }

      &:hover {
        color: #4f5af5;
      }

      &.active {
        color: #3f48c4;
      }
    }
  }
}
</style>
